@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap');

@import "./scss/bootstrap_variables";

@import "bootstrap/scss/bootstrap.scss";
@import "~flatpickr/dist/flatpickr.css";
@import "~@fortawesome/fontawesome-free/css/solid.css";
@import "~@fortawesome/fontawesome-free/css/fontawesome.css";
@import "~tablesort/tablesort.css";
@import "./scss/layout";
@import "./scss/typography";
@import "./scss/tables";
@import "./scss/forms";
@import "./scss/buttons";
@import "./scss/card";
@import "./scss/accordion";
@import "./scss/pagination";
@import "./scss/badge";
@import "./scss/lists";
@import "./scss/list-group";
@import "./scss/definition-lists";
@import "./scss/modal";
@import "./scss/utilities";
@import "./scss/header";
@import "./scss/navigation";
@import "./scss/search";
@import "./scss/global-search";
@import "./scss/environment-flag";
@import "./scss/questions";
@import "./scss/group-state";

body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  letter-spacing: $letter-spacing;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

b, strong {
  font-weight: 600;
}

// .curriculum {
//   background-color: #fff;
// }

// .facilitator {
//   background-color: #fff;
// }

// Removes autofill button from input fields in Safari
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
  width: 0px !important;
}

.sanyas.container-fluid {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.navbar {
  border-radius: 0;
  border: none;
  background-color: rgb(203, 0, 44);
  color: rgb(255, 255, 255);

  margin-bottom: 0;

  .navbar-brand {
    color: rgb(255, 255, 255);
  }

  .nav-item {
    .nav-link.active {
      color: rgb(255, 255, 255);
      font-weight: bolder;
    }
    .nav-link {
      color: rgb(255, 255, 255);
    }
  }

  .active {
    text-decoration: underline;
  }
}

// .form-select {
//   appearance: none;
//   -webkit-appearance: none;
//   -moz-appearance :none;
// }

// // Add spacing around dropdowns with glyphicons instead of text
// .dropdown .dropdown-toggle .glyphicon {
//   margin-right: 8px;
//   margin-top: 2px;
// }

#registrar-groups-index {
  select {
    width: 100%;
  }
}

// a {
//   color: #337ab7;
//   text-decoration: none;
// }

// .btn-link {
//   color: #337ab7;
//   text-decoration: none;
// }

// .page-item.active .page-link {
//   background-color: #337ab7;
//   border-color: #337ab7;
// }

// .page-link {
//   color: #337ab7;
// }

// Executive Report Styling
table.executive_report {
  @extend .table;
}

table.executive_report td,
table.executive_report th {
  border:1px solid #ebebeb;
  font-size:11px;
}

table.executive_report .blank-cell,
table.executive_report .blank-cell {
  border:none;
}

table.executive_report th.time-range-title {
  font-size:14px;
  padding:7px;
}

table.executive_report th.time-range-title.time_range_even,
table.executive_report th.time-range-sub-title.time_range_even {
  color: #ad7416;
}

table.executive_report th.time-range-title.time_range_odd,
table.executive_report th.time-range-sub-title.time_range_odd {
  color: #d75117;
}

table.executive_report th.time-range-title.time_range_all_years,
table.executive_report th.time-range-sub-title.time_range_all_years {
  color: #811208;
}

table.executive_report th.row-label {
  min-width: 220px;
  text-align: left;
}

table.executive_report th.row-label.row-label-course-type {
  font-weight: normal;
}

table.executive_report td.data-cell {
  text-align: center;
  min-width: 45px;
}

table.executive_report td.data-cell,
table.executive_report th.row-label,
table.executive_report th.data-column-header {
  cursor: crosshair;
}

table.executive_report th.row-label-totals,
table.executive_report td.data-cell.totals {
  background: #560d00;
  color: white;
  border: 1px solid #660705;
}

table.executive_report td.click-to-highlight,
table.executive_report th.click-to-highlight {
  color: #ffffff;
  font-weight: bold;
  background-color: #00aa00 !important;
  border: 1px solid #00aa00 !important;
}

table.executive_report th.row-label-health-authority {
  background-color: #f2f2f2;
}

table.executive_report td.time_range_even,
table.executive_report th.time_range_even {
  border: 1px solid #f1e7c4;
}

table.executive_report .time_range_even {
  background-color: #fefcdf;
}

table.executive_report .time_range_even.data-cell.health-authority {
  background-color: #fff7cb;
}

table.executive_report td.time_range_odd,
table.executive_report th.time_range_odd {
  border: 1px solid #ebdfc8;
}

table.executive_report .time_range_odd {
  background-color: #fff1d6;
}

table.executive_report .time_range_odd.data-cell.health-authority {
  background-color: #ffe6c6;
}

table.executive_report td.time_range_all_years,
table.executive_report th.time_range_all_years {
  border: 1px solid #ebdfc8;
}

table.executive_report .time_range_all_years {
  background-color: #ffecd6;
}

table.executive_report .time_range_all_years.data-cell.health-authority {
  background-color: #ffe5dd;
}

.delete-rep-container {
  height: 100%
}

.delete-icon {
  color: red;
}

@media (min-width: 992px){
  .block-until-text {
    text-align: end;
  }

  .side-nav {
    display: block !important;
    height: 100vh;
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .breadcrumb {
    margin-top: 1rem;
  }
}

.report-card {
  display: flex;
  flex-direction: column;
}

.report-button {
  margin-top: auto;
}

.toast{
  background-color: $primary
}

.toast-body {
  color: white;
  align-items: center;
  width: 100%;
}

.toast-close {
  position: absolute;
  right: 10px;
  top: 15px;
}

.turbo-busy {
  display: none;

  form[aria-busy] & {
    display: inline-block;
  }
}
